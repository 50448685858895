import './App.css';
import {FileUploader} from "./FileUploader";
import React, {useEffect, useState} from "react";
import {Box, ChakraProvider, Container, Image, Text} from "@chakra-ui/react";
import {theme} from "./Theme";
import {ImageEditor} from "./ImageEditor";
import {imageFromFile, imageFromUrl} from "./utils";
import logo from './fundacja-logo.svg'
import {frameUrls} from "./frames";


function App() {
    const [image, setImage] = useState(null);
    const [frames, setFrames] = useState([]);

    useEffect(() => {
        Promise.all(frameUrls.map(imageFromUrl))
            .then(imgs => {
                setFrames(imgs)
            })
    }, []);


    async function onFileSelected(file) {
        let newImage;

        try {
            newImage = await imageFromFile(file);
        } catch {
            newImage = null;
        }

        setImage(newImage);
    }

    function onReset() {
        setImage(null)
    }

    let content;

    if (image) {
        content = <ImageEditor image={image} onCloseClick={onReset} frames={frames}/>
    } else {
        content = <FileUploader onFileSelected={onFileSelected}></FileUploader>
    }

    return (
        <ChakraProvider theme={theme}>
            <Container centerContent paddingTop={20}>
                <Image src={logo} h={{base: '70px', sm: '100px', md: '200px'}} pos='fixed' left={{base: '10px', sm: '20px', md: '40px'}} top={{base: '5px', sm: '20px', md: '30px'}} />
                {content}
                <Box fontSize='xs'
                     color='gray.500'
                     maxW='300px'
                     textAlign='center' mt='8'>
                    <Text mb='80px'>
                        Stworzone przez MONOCODE Paweł Smoleński dla Technologie Diabetyka na licencji MIT.
                    </Text>
                </Box>
            </Container>

        </ChakraProvider>
    )
}


export default App;
