import {Canvas} from "./Canvas";
import {useState} from "react";
import {Card} from "@chakra-ui/react";

export function ImagePreview({image, scale, frame, onDataUrlChange}) {
    const [panning, setPanning] = useState(false)
    const [startPos, setStartPos] = useState(null)
    const [panDistance, setPanDistance] = useState({x: 0, y: 0})
    const [offset, setOffset] = useState({x: 0, y: 0})

    function startPanning(e) {
        setPanning(true)
        setStartPos({x: e.pageX, y: e.pageY})
    }

    function stopPanning() {
        setPanning(false);
        setOffset({
            x: offset.x + panDistance.x,
            y: offset.y + panDistance.y
        })
        setPanDistance({
            x: 0,
            y: 0
        })
    }

    function pan(e) {
        if (!panning) {
            return;
        }

        setPanDistance({
            x: e.pageX - startPos.x,
            y: e.pageY - startPos.y
        })
    }

    return <div onMouseDown={startPanning} onMouseMove={pan} onMouseUp={stopPanning} onMouseLeave={stopPanning}>
        <Card>
            <Canvas image={image}
                    scale={scale}
                    offsetX={offset.x + panDistance.x}
                    offsetY={offset.y + panDistance.y}
                    frame={frame}
                    onChange={onDataUrlChange}></Canvas>
        </Card>
    </div>;
}
