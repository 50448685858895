import {ImagePreview} from "./ImagePreview";
import React, {useEffect, useState} from "react";
import {Box, Button, Card, HStack, Image, SimpleGrid} from "@chakra-ui/react";

export function ImageEditor({image, onCloseClick, frames}) {
    const [scale, setScale] = useState(1)
    const [selectedFrame, setSelectedFrame] = useState(null)
    const [dataUrl, setDataUrl] = useState(null)

    useEffect(() => {
        setSelectedFrame(frames[0] ?? null)
    }, [frames]);

    function onDownloadClick() {
        const link = document.createElement('a')
        link.href = dataUrl
        link.download = 'profile-photo.png'
        link.click()
    }

    function zoomIn() {
        setScale(scale + 0.2);
    }

    function zoomOut() {
        setScale(scale - 0.2);
    }

    function zoomReset() {
        setScale(1);
    }

    const canZoomIn = scale < 4;
    const canZoomOut = scale > 1;

    return <>
        <Box position='relative'>
            <Button variant='ghost' size='sm' padding='0' position='absolute' top='0px' right='-40px' onClick={onCloseClick} title="Remove">&times;</Button>
            <ImagePreview image={image} scale={scale} frame={selectedFrame} onDataUrlChange={setDataUrl}/>
        </Box>
        <HStack justify='center' mt="20px">
            <Button size='md'  onClick={zoomIn} isDisabled={!canZoomIn}>+</Button>
            <Button size='md'  onClick={zoomOut} isDisabled={!canZoomOut}>-</Button>
        </HStack>
        <SimpleGrid mt='40px' columns={{base: 2, md: 3}} spacing={10}>
            {
                frames.map((f, i) => (
                    <Button onClick={() => setSelectedFrame(f)}
                            h='auto'
                            borderRadius='50%'
                            padding='10px' key={i}>
                        <Image src={f.src} h="150px" w="150px" p="5px"/>
                    </Button>
                ))
            }
        </SimpleGrid>
        <Card mt='50px'>
            <Button size='lg' colorScheme='whatsapp' onClick={onDownloadClick}>Pobierz</Button>
        </Card>
    </>
}