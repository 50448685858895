import {useEffect, useRef} from "react";
import './Canvas.css';

export function Canvas({image, frame, scale, offsetX, offsetY, onChange}) {
    const canvasRef = useRef(null);

    useEffect(() => {
        if (!canvasRef.current || !image) {
            return
        }

        const canvas = canvasRef.current;
        const ctx = canvas.getContext('2d');

        ctx.save()

        ctx.clearRect(0, 0, canvas.width, canvas.height);

        let sx = (canvas.width / 2) - image.width / 2;
        let sy = (canvas.height / 2) - image.height / 2;

        ctx.translate(sx, sy);
        ctx.translate(offsetX * (1280 / 256), offsetY * (1280 / 256));
        ctx.scale(scale, scale);

        ctx.drawImage(
            image,
            0,
            0
        );

        ctx.resetTransform();

        if (frame) {
            ctx.drawImage(
                frame,
                0,
                0,
                canvas.width,
                canvas.height
            );
        }

        onChange(canvas.toDataURL("image/png"))

    }, [image, frame, scale, offsetX, offsetY]);

    return (
        <canvas ref={canvasRef} id="canvas" width="1280" height="1280"></canvas>
    )
}