import frame01 from './frame01.svg';
import frame02 from './frame02.svg';
import frame03 from './frame03.svg';
import frame04 from './frame04.svg';
import frame05 from './frame05.svg';
import frame06 from './frame06.svg';

export const frameUrls = [
    frame01,
    frame02,
    frame03,
    frame04,
    frame05,
    frame06
]