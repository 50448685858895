export function imageFromFile(file) {
    const fileUrl = URL.createObjectURL(file);

    return imageFromUrl(fileUrl)
        .finally(() => {
            URL.revokeObjectURL(fileUrl);
        })
}

export function imageFromUrl(url) {
    return new Promise((resolve, reject) => {
        let img = new Image();

        img.onload = () => {
            resolve(img);
        };
        img.onerror = (e) => {
            reject(e);
        };

        img.src = url;
    });
}